import React from "react"
import firebase from "gatsby-plugin-firebase"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Hero from "../../components/hero/hero"
import Content from "../../components/content/content"

import "../../styles/styles.scss"

const EnglishIndexPage = () => {
  if (!!firebase.analytics && !!firebase.performance) {
    firebase.analytics();
    firebase.performance();
  }

  return (
    <Layout>
      <SEO
        lang="en"
        description="Ogado it's the new app that lets you manage your digital library. Come join our community"
        title="Ogado"
        keywords={[
          "ogado",
          "entertainment",
          "digital",
          "movies",
          "tv series",
          "music",
          "books",
          "videogames",
          "the boys",
        ]}
      />
      <Hero lang="en" />
      <Content lang="en" />
    </Layout>
  )
}

export default EnglishIndexPage
